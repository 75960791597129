import React, { useEffect, useState } from "react";
import CustomModal from "../../../../Components/Common/Modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Spinner,
} from "reactstrap";
import * as moment from "moment";
import Textfield from "../../ui/TextField";
import Rating from "react-rating";
import useHookForm from "../../../../hooks/useHookForm";
import { Controller } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { ReviewPublishPost } from "../../../../api";
import { toast } from "react-toastify";
import createSchema from "../../../../helpers/createSchema";
import Picker from "emoji-picker-react";
const values = {
  content: "",
  status: null,
};
const schema = createSchema({});
const ReviewInformation = ({ data, productId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const client = useQueryClient();
  const { control, handleSubmit, reset, watch, setValue } = useHookForm(
    values,
    schema
  );

  const addEmoji = (e, emojiObject) => {
    setValue("content", watch("content") + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const Submit = async (res) => {
    setLoading(true);
    const datas = {
      status: data?.status,
      content: res.content,
    };

    try {
      await ReviewPublishPost(
        productId,
        datas,
        data?.reply === null ? "POST" : "PUT"
      );
      client.invalidateQueries({ queryKey: ["review"] });
    } catch (error) {
      toast(error?.data?.error?.errorMessage, {
        type: "error",
        theme: "colored",
      });
      reset();
    } finally {
      setLoading(false);
    }
  };
  const Status = () => {
    switch (data?.status) {
      case 0:
        return <span className="text-info">{t("Created")}</span>;
      case 1:
        return <span className="text-success">{t("Active")}</span>;
      case 2:
        return <span className="text-danger ">{t("Not active")}</span>;
    }
  };

  useEffect(() => {
    if (data) {
      setValue("content", data?.reply?.content);
    }
  }, [data, setValue]);

  return (
    <div
      className="w-100"
      style={{
        position: "sticky",
        top: "80px",
        zIndex: 999,
      }}
    >
      <Card
        style={{
          minHeight: "500px",
          maxHeight: "500px",
          minWidth: "500px",
          maxWidth: "500px",
          overflow: "auto",
        }}
      >
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h3>{t("review")}</h3>
          <h3>{Status()}</h3>
        </CardHeader>
        <CardBody className={!data && `d-flex align-items-center text-center`}>
          {data ? (
            <div>
              <h3>{data?.for?.name}</h3>

              <div>
                <div className="d-flex justify-content-between align-items-start ">
                  <div>
                    <div className="d-flex gap-2 align-items-center fw-semibold">
                      <span style={{ fontSize: "14px" }}>
                        {data?.user?.firstname}
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {data?.user?.lastname}
                      </span>
                    </div>
                    <span>{moment(data?.date).format("DD MMMM")}</span>
                  </div>

                  <Rating
                    initialRating={data?.rating}
                    emptySymbol="mdi mdi-star-outline text-muted "
                    fullSymbol="mdi mdi-star text-warning "
                    className="fs-3"
                    readonly
                  />
                </div>

                <p>{data?.content}</p>
              </div>

              <div hidden={!data?.reply?.content}>
                <span className="fw-semibold" style={{ fontSize: "16px" }}>
                  {t("seller's reply")}
                </span>
                <p>{data?.reply?.content}</p>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center text-center mx-auto">
              <p>{t("Select a comment to review details or reply")}</p>
            </div>
          )}
        </CardBody>
        <CardFooter hidden={!data || data?.status !== 1}>
          <Form
            className="d-flex flex-column gap-2"
            onSubmit={handleSubmit(Submit)}
          >
            <div className="d-flex gap-3 align-items-center">
              <Controller
                control={control}
                name="content"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("Your answer") + "..."}
                    type="textarea"
                    name={"content"}
                  />
                )}
              />

              <Button
                color="link"
                outline
                type="button"
                onClick={() => setShowEmojiPicker((prev) => !prev)}
              >
                😊
              </Button>
              {showEmojiPicker && (
                <div
                  style={{
                    position: "absolute",
                    top: 40,
                    right: 0,
                    zIndex: 1000,
                  }}
                >
                  <Picker onEmojiClick={addEmoji} skinTone="neutral" />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button disabled={!watch("content")} type="submit">
                {loading ? (
                  <Spinner size={"sm"} />
                ) : data?.reply !== null ? (
                  t("Save")
                ) : (
                  t("Send")
                )}
              </Button>
            </div>
          </Form>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ReviewInformation;
