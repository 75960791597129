import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getOrdersId } from "../../../api";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { Badge, Card, CardBody, Col, Label } from "reactstrap";
import Textfield from "../ui/TextField";
import Rating from "react-rating";
import { prettify } from "../ui/prettify";
const OrdersSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ["ordersId", id],
    queryFn: () => getOrdersId(id),
  });

  return (
    <div className="page-content ">
      <div className="d-flex justify-content-between align-items-center w-50">
        <div className="d-flex align-items-start gap-3">
          <i
            className="ri-arrow-left-line fs-3 cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          <div className="d-flex align-items-end  gap-2">
            <h2>Заявка № {data?.result?.id}</h2>{" "}
            <h4 className="text-primary">
              {t("from")}{" "}
              {moment(data?.result?.createdDate).format("MM.DD.YYYY")}
            </h4>{" "}
          </div>
        </div>
        <div>
          <h5
            className={
              data?.result?.status === 1
                ? "text-danger"
                : data?.result?.status === 2
                ? "text-primary"
                : null
            }
          >
            {data?.result?.status === 1
              ? t("Application closed")
              : data?.result?.status === 2
              ? t("Go to deal")
              : null}
          </h5>
        </div>
      </div>
      <div className="d-flex " style={{ gap: "10rem" }}>
        <Card className="w-50 p-3">
          <h4 style={{ fontWeight: 650 }}>{t("General information")}</h4>
          <CardBody className="pe-4">
            <Textfield name={"Phone number"} source={data?.result?.phone} />
            <Textfield name={"full_name"} source={data?.result?.fullname} />
            <Textfield
              name={"Transaction type"}
              source={
                data?.result?.isInstallment
                  ? t("Buy in installments")
                  : t("Buy with compensation")
              }
            />
            <Textfield
              name={"Installation address"}
              source={data?.result?.address}
            />
            <Textfield name={"Comments"} source={data?.result?.comment} />
          </CardBody>

          <CardBody className="pe-4 ">
            {data?.result?.buildDetails.length ||
              (data?.result?.buildDetails && (
                <div className="">
                  <h5 style={{ fontWeight: 650 }}>
                    {t("General information about the installation location")}
                  </h5>
                  <Textfield
                    name={"Area in sq.m"}
                    source={data?.result?.buildDetails.Area_in_sqm}
                  />
                  <Textfield
                    name={"Choose the type of roof"}
                    source={t(data?.result?.buildDetails.Choose_roof_type)}
                  />
                  <Textfield
                    name={"Choose the type of roof2"}
                    source={t(data?.result?.buildDetails.Choose_roof_type2)}
                  />
                  <Textfield
                    name={"Select building type"}
                    source={t(data?.result?.buildDetails.Choose_building_type)}
                  />
                  <Textfield
                    name={"Enter usable roof area sq.m."}
                    source={
                      data?.result?.buildDetails.Enter_usable_roof_area_sqm
                    }
                  />
                  <Textfield
                    name={"Select installation location"}
                    source={t(
                      data?.result?.buildDetails.Select_installation_location
                    )}
                  />
                  <Textfield
                    name={"Select precrit type"}
                    source={t(
                      data?.result?.buildDetails.Choose_type_of_precrimination
                    )}
                  />
                  <Textfield
                    name={"Select load-bearing wall material"}
                    source={t(
                      data?.result?.buildDetails
                        .Choose_a_load_bearing_wall_material
                    )}
                  />
                </div>
              ))}
          </CardBody>

          <CardBody>
            <h5 style={{ fontWeight: 650 }}>{t("Electrical Information")}</h5>
            <div>
              <Textfield
                name={"Distance in m"}
                source={t(data?.result?.wiringDetails?.Distance_in_m)}
              />
              <Textfield
                name={"Select wire size"}
                source={t(data?.result.wiringDetails?.Select_wire_size)}
              />
              <Textfield
                name={"Select wiring material"}
                source={t(data?.result.wiringDetails?.Select_wiring_material)}
              />
              <Textfield
                name={"Enter the distance to the meter (m)"}
                source={t(
                  data?.result?.wiringDetails?.Enter_the_distance_to_the_counter
                )}
              />
              <Textfield
                name={"Select the service life of the electrical wiring"}
                source={t(
                  data?.result?.wiringDetails?.Select_the_lifetime_of_the_wiring
                )}
              />
            </div>
          </CardBody>
        </Card>

        <Col xl={3}>
          <Card>
            <Link
              to={`/products/${data?.result?.product?.productId}`}
              className="avatar-group-item"
              id="img2"
            >
              <CardBody>
                <div className="col-md-12">
                  {/* {data?.result?.product?.files?.map((item) => ( */}
                  <img
                    className="h-25 w-100"
                    src={data?.result?.product?.files[0]?.url}
                    alt=""
                  />
                  {/* // ))} */}
                  <Rating
                    initialRating={5}
                    emptySymbol="mdi mdi-star-outline text-muted "
                    fullSymbol="mdi mdi-star text-warning "
                    className="fs-4"
                  />
                </div>
                <p className="fs-4">{data?.result?.product?.product?.name}</p>

                <p className="fs-5" style={{ color: "#FF8F00" }}>
                  {prettify(
                    data?.result?.product?.prices
                      ?.filter((item) => item.type === "Price")
                      .map((item) => item.value)
                  )}
                </p>
                <p style={{ opacity: 0.4, fontSize: "15px" }}>
                  {t("Price including compensation")}
                </p>
                <p className="fs-5">
                  {prettify(
                    data?.result?.product?.prices
                      ?.filter((item) => item.type === "PayAmount")
                      .map((item) => item.value)
                  )}
                </p>
                <p style={{ opacity: 0.4, fontSize: "15px" }}>
                  {t("Installment price")}
                </p>
                <div className="d-flex align-items-start gap-2">
                  {data?.result?.product?.compensationOnly ? (
                    <>{"Не доступно"}</>
                  ) : (
                    <>
                      <p className="fs-5">
                        {prettify(
                          data?.result?.product?.prices
                            ?.filter((item) => item.type === "Installment")
                            .map((item) => item.value / 36)
                        )}
                      </p>
                      <Badge color="warning" style={{ fontSize: "0.7rem" }}>
                        {`x 36 ${t("month")}`}
                      </Badge>
                    </>
                  )}
                </div>
              </CardBody>
            </Link>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default OrdersSingle;
