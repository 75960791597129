import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getEmployessQuery, getPositionQuery } from "../../../queries";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "../../../Components/Common/Loader";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import createSchema from "../../../helpers/createSchema";
import useHookForm from "../../../hooks/useHookForm";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../../Components/Common/Modal";
import { Controller } from "react-hook-form";
import Select from "react-select";
const Employees = () => {
  const { t } = useTranslation();
  const schema = createSchema({});
  const values = {
    desc: true,
    size: 20,
    page: 1,
    fio: "",
    address: "",
    positionId: null,
  };
  const {
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useHookForm(values, schema);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState({
    ...values,
  });
  const tableKey = "employees";

  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { data, isFetched } = useQuery({
    ...getEmployessQuery(filter),
  });

  const { data: position } = useQuery({
    ...getPositionQuery(),
  });

  const Submit = async (data) => {
    const datas = {
      positionId: data?.positionId?.value,
      fio: data?.fio,
      address: data?.address,
      page: data.page,
      size: watch("size"),
    };

    setFilter(datas);
    toggleModal();
  };

  const resetValue = () => {
    reset();
    setFilter();
    toggleModal();
  };

  const columns = useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      filterable: false,
    },
    {
      Header: t("Full name of the client"),
      accessor: (row) =>
        row?.lastName + " " + row?.firstName + " " + row?.middleName,
      filterable: false,
      Cell: ({ row }) => (
        <Link to={`/employees/${row.original.id}`}>
          {row?.original.lastName +
            " " +
            row?.original.firstName +
            " " +
            row?.original.middleName}
        </Link>
      ),
    },

    {
      Header: t("Phone number"),
      accessor: "phoneNumber",
      filterable: false,
    },
    {
      Header: t("Job title"),
      accessor: (row) => row?.position?.name,
      filterable: false,
    },

    {
      Header: t("Name of the organization"),
      accessor: (row) => row?.organization?.information?.organizationName,
      filterable: false,
    },

    {
      Header: t("Status"),
      accessor: "state",
      filterable: false,
      Cell: ({ value }) => {
        switch (value) {
          case 0:
            return <p>{t("Not active")}</p>;
          case 1:
            return <p>{t("Active")}</p>;
        }
      },
    },
  ]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3 ">
              <h2>{t("Employees")}</h2>
              <div className="d-flex align-items-center gap-3 ">
                {/* <Button className="d-flex gap-1 btn btn-success align-items-center ">
                  {t("Export to Excel")}
                  <i className=" ri-arrow-right-s-line "></i>
                </Button> */}
                {/* <Button
                  onClick={toggleModal}
                  className="btn-light d-flex align-items-center gap-1"
                >
                  <i className="ri-equalizer-fill"></i>
                  {t("Filter")}
                </Button> */}
                <Button
                  onClick={() => navigate("/employees/add")}
                  className="d-flex gap-1 btn btn-primary align-items-center"
                >
                  {t("Adding an employee")}
                  <i className=" ri-add-line"></i>
                </Button>
              </div>
            </div>
            <div>
              {isFetched ? (
                <>
                  <TableContainer
                    pagination={{
                      currentPage: watch("page"),
                      totalPages: data?.pagination?.TotalPages,
                      onChange: (page) => setValue("page", page),
                    }}
                    columns={columns}
                    data={data?.result.items ? data?.result.items : []}
                    isAddUserList={false}
                    divClass="table-responsive table-card mb-1 "
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  >
                    {data?.result?.items?.length === 0 && (
                      <div className="text-center ">{t("No data")}</div>
                    )}
                  </TableContainer>

                  <Input
                    type="select"
                    name="pageSize"
                    id="pageSize"
                    value={watch("size")}
                    onChange={handlePageSizeChange}
                    className="w-auto"
                  >
                    <option value={20}>{t("20")}</option>
                    <option value={50}>{t("50")}</option>
                    <option value={100}>{t("100")}</option>
                    <option value={200}>{t("200")}</option>
                  </Input>
                </>
              ) : (
                <Spinner />
              )}
              {/* <CustomModal
                centered
                isOpen={isModalOpen}
                toggleModal={toggleModal}
                title={t("Filter")}
                content={
                  <Form className="d-flex flex-column gap-3">
                    <div>
                      <Label style={{ fontWeight: 600 }}>
                        {t("Job title")}
                      </Label>
                      <Controller
                        name="positionId"
                        control={control}
                        className="bg-white"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={position?.result?.items?.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Label style={{ fontWeight: 600 }}>
                        {t("Search by full name")}
                      </Label>
                      <Controller
                        control={control}
                        name="fio"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("Enter your full name")}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Label style={{ fontWeight: 600 }}>
                        {t("Search by address")}
                      </Label>
                      <Controller
                        control={control}
                        name="address"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("Enter address")}
                          />
                        )}
                      />
                    </div>
                  </Form>
                }
                footerText={
                  <div className="d-flex justify-content-end gap-2 ">
                    <Button
                      disabled={
                        !watch("fio") &&
                        !watch("positionId")?.value &&
                        !watch("address")
                      }
                      className="btn-light"
                      onClick={resetValue}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled={
                        !watch("fio") &&
                        !watch("positionId")?.value &&
                        !watch("address")
                      }
                      className="btn-primary"
                      onClick={handleSubmit(Submit)}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                }
              /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Employees;
