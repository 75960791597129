import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReserveSubOrdersByIdQuery } from "../../../queries";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import { prettify } from "../ui/prettify";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Input } from "../../../Components/atoms/input";
import { ToastContainer, toast } from "react-toastify";
import { ReserveSubOrdersByIdPut } from "../../../api";
import * as Yup from 'yup';

const form = {
  state: 0,
  reason: "",
};
const ReserveSingle = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    reason: Yup.string().when('state.value', {
      is: 4,  
      then: Yup.string().required(t("required_field_error")),
      otherwise: Yup.string(), 
    }),
  });
  const { id, subOrderId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const clients = useQueryClient()
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useHookForm(form, schema);
  const { data, isLoading } = useQuery({
    ...getReserveSubOrdersByIdQuery(id, subOrderId),
  });

  useEffect(() => {
    if (data) {
      const value = {
        state:
          data.result.state !== null
            ? {
                value: data.result.state,
                label:
                  data.result.state === 0
                    ? t("Новый заказ")
                    : data.result.state === 1
                    ? t("Идет подготовка")
                    : data.result.state === 2
                    ? t("Отправленный")
                    : data.result.state === 3
                    ? t("Завершено")
                    : data.result.state === 4
                    ? t("Отменено")
                    : t("Возврат денег"),
              }
            : null,
            reason: data.result.reason
      };
      reset(value);
    }
  }, [data, reset]);

  const onSubmit = handleSubmit(async (res) => {
    setLoading(true);
    const state = res.state?.value;
    const datas = {
      state: state,
      reason: res.reason,
    };

    try {
      await ReserveSubOrdersByIdPut(id, subOrderId, datas);

     
      
      toast(t("Заказ успешно изменен"), {
        type: "success",
        theme: "colored",
        position: "top-center",
      });
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
      setTimeout(() => {
        navigate("/reserve");
      }, 2000);
    } catch (error) {
      toast(error.data?.error?.errorMessage || error.data.message, {
        type: "error",
        theme: "colored",
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <ToastContainer />
          <div className="d-flex align-items-start gap-3 mb-2">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h2>{t("order_information")}</h2>
          </div>
          <Card style={{ maxWidth: "50%" }}>
            <CardHeader>{data?.result?.id}</CardHeader>
            {data?.result?.items?.map((item, i) => (
              <CardBody
                key={i}
                className="d-flex align-items-center justify-content-between"
              >
                <div className="d-flex gap-3 align-items-start ">
                  <img
                    style={{
                      maxWidth: "100px",
                      width: "100px",
                      maxHeight: "100px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    src={`${item.variation?.files[0]?.url}`}
                    alt=""
                  />
                  <span style={{ width: "300px" }}>
                    {item.variation?.product.name}
                  </span>
                </div>
                <div>
                  {prettify(
                    item?.variation?.prices?.find(
                      (item) => item.type === "Price"
                    )?.value + " so'm"
                  )}
                </div>
                <div>{item.count}</div>
              </CardBody>
            ))}
          </Card>

          <Form onSubmit={onSubmit}>
            <div className="mb-3 w-50">
              <Label for="positionId">{t("status")}</Label>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: 0, label: t("Новый заказ") },
                      { value: 1, label: t("Идет подготовка") },
                      { value: 2, label: t("Отправленный") },
                      { value: 3, label: t("Завершено") },
                      { value: 4, label: t("Отменено") },
                      { value: 5, label: t("Возврат денег") },
                    ]}
                    placeholder="Select Position"
                  />
                )}
              />
          
            </div>
            <div className="mb-3 w-50">
              <Input
                name="reason"
                control={control}
                errors={errors}
                label={t("reason")}
                inputProps={{
                  placeholder: t("reason"),
                }}
              />
              
            </div>
            <Button
              className="btn btn-primary d-flex align-items-center gap-2  "
              type="submit"
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              {t("Save")}
              <i className="ri-check-line"></i>
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ReserveSingle;
