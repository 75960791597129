import React from "react";
import { Controller } from "react-hook-form";
import { Label, Input as InputB, InputGroup, InputGroupText } from "reactstrap";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { flow } from "lodash";
import removeChars from "./removeChars";

const onKeyDown = (type, e, isInteger) => {
  switch (type) {
    case "number":
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "+" ||
        e.key === "-" ||
        e.key === "," ||
        (isInteger ? e.key === "." : false)
      ) {
        e.preventDefault();
      }
      break;
    default:
      return null;
  }
};

const InputMasked = ({
  name,
  control,
  errors,
  label,
  inputProps = {
    type: "text",
  },
  className,
  maskProps,
  leftElementValue,
  rightElementValue,
  isInteger,
  maxLength,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {label && (
        <Label htmlFor={name} className="form-label">
          {t(label)}
        </Label>
      )}
      {control && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const {
              onChange,
              onBlur,
              ref,
              value: fieldValue,
              name: fieldName,
            } = field;

            const controllerProps = {
              onBlur,
              onChange,
              value: fieldValue,
              name: fieldName,
            };

            return (
              <InputGroup className="input-group">
                {leftElementValue && (
                  <InputGroupText>{leftElementValue}</InputGroupText>
                )}

                <InputMask
                  maskChar={"ㅤ"}
                  {...controllerProps}
                  {...maskProps}
                >
                  {(maskInputProps) => (
                    <InputB
                      type="text"
                      className={`form-control ${className}`}
                      onKeyDown={(e) =>
                        onKeyDown(inputProps.type, e, isInteger)
                      }
                      onWheel={(e) => e.target.blur()}
                      {...inputProps}
                      {...maskInputProps}
                      ref={ref}
                    />
                  )}
                </InputMask>

                {rightElementValue && (
                  <InputGroupText>{rightElementValue}</InputGroupText>
                )}
                {errors?.[name] && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {t(errors[name]?.message)}
                  </div>
                )}
              </InputGroup>
            );
          }}
        />
      )}
    </div>
  );
};

export default InputMasked;
