import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Spinner,
  TabContent,
  TabPane,
  Input as ReactInput,
} from "reactstrap";
import { useFieldArray } from "react-hook-form";
import useHookForm from "../../../hooks/useHookForm";
import { ProductsPost, getAttributesId, uploadFiles } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCategoriesQuery, getProfileQuery } from "../../../queries";
import classnames from "classnames";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import AddProductForm from "./Components/add-product-form";
import * as yup from "yup";
import ProductForm from "./Components/product-form";

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
    { type: "Sale", value: "" },
  ],
  files: [],
  saleType: [],
};

const AddProducts = () => {
  const createSchema = () => {
    return yup.object().shape({
      attrs: yup.array().of(
        yup.object().shape({
          value: yup.mixed().when("isRequired", {
            is: true,
            then: yup
              .mixed()
              .when("filter.filterType", {
                is: (val) => val === "Range" || val === "Price",
                then: yup.string().required(t("required_field_error")),
              })
              .when("filter.filterType", {
                is: (val) => val === "SingleSelect",
                then: yup.string().required(t("required_field_error")),
              })
              .when("filter.filterType", {
                is: (val) => val === "MultipleSelect",
                then: yup.string().required(t("At least one must be selected")),
                otherwise: yup.string().nullable(),
              }),
            otherwise: yup.string().nullable(),
          }),
          valueTranslations: yup.mixed().when("isRequired", {
            is: true,
            then: yup.mixed().when("isValueTranslated", {
              is: true,
              then: yup.object().shape({
                ru: yup.string().required(t("required_field_error")),
                "uz-Cyrl-UZ": yup.string().required(t("required_field_error")),
                "uz-Latn-UZ": yup.string().required(t("required_field_error")),
              }),
              otherwise: yup.object().nullable(),
            }),
            otherwise: yup.object().nullable(),
          }),
        })
      ),
      files: yup
        .array()
        .min(1, t("At least one must be selected"))
        .required(t("At least one must be selected")),
      saleType: yup
        .array()
        .min(1, t("At least one must be selected"))
        .required(t("At least one must be selected")),
    });
  };

  const schema = createSchema();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useHookForm(values, schema);
  console.log(errors);
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [fileInputs, setFileInputs] = useState([0]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [productLoading, setProductLoading] = useState(false);

  const client = useQueryClient();
  const navigate = useNavigate();
  const handleFileUpload = (acceptedFiles, index) => {
    const updatedImageFiles = [...imageFiles];
    updatedImageFiles[index] = acceptedFiles[0];
    setImageFiles(updatedImageFiles);

    const updatedPreviews = [...filePreviews];
    updatedPreviews[index] = URL.createObjectURL(acceptedFiles[0]);
    setFilePreviews(updatedPreviews);

    if (index === fileInputs.length - 1) {
      setFileInputs([...fileInputs, fileInputs.length]);
    }
    setValue("files", [...imageFiles, acceptedFiles[0]]);
  };

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });

  const { data: profile } = useQuery({
    ...getProfileQuery(),
  });

  const handleSaleTypeChange = (type) => {
    const saleTypeValues = watch("saleType");
    const updatedSaleType = saleTypeValues.includes(type)
      ? saleTypeValues.filter((t) => t !== type)
      : [...saleTypeValues, type];
    setValue("saleType", updatedSaleType);
  };

  const handleMultipleSelectChange = (field, value, e) => {
    let newValue = field.value.split(",").filter(Boolean);
    if (e.target.checked) {
      newValue.push(value.trim());
    } else {
      newValue = newValue.filter((v) => v !== value.trim());
    }
    field.onChange(newValue.join(","));
  };

  const calculateSaleType = () => {
    return watch("saleType").reduce((acc, type) => acc + type, 0);
  };

  const Submit = async (data) => {
    setLoading(true);
    const attributeId = watch("categoryId")?.value;
    try {
      const res = await getAttributesId(attributeId);
      toggleTab(activeTab + 1, 100);
      const arr = res.item.map((item) => ({
        ...item,
        attributeId: item.id,
        value: "",
        valueTranslations: {
          ru: "",
          "uz-Latn-UZ": "",
          "uz-Cyrl-UZ": "",
        },
      }));
      setValue("attrs", arr);
      client.invalidateQueries({ queryKey: ["products"] });
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const ProductSubmit = async (data) => {
    if (!data?.prices?.[0].value) {
      return setError("prices[0].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }

    if (!data?.prices?.[1].value) {
      return setError("prices[1].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }
    if (
      data.prices[2].value !== "" &&
      +data.prices[0].value > +data.prices[2].value
    ) {
      return setError("prices[2].value", {
        type: "manual",
        message: t("price_2_error"),
      });
    }

    setProductLoading(true);
    let index = 1;
    const uploadedFiles = [];

    for (const file of imageFiles) {
      const formData = new FormData();
      formData.append("files", file);

      const resImage = await uploadFiles(formData);
      const imageObj = resImage?.[0];

      uploadedFiles.push({
        order: index,
        url: imageObj?.url,
        fileId: imageObj?.id,
        variationId: null,
        productId: null,
      });

      index++;
    }

    const names = Object.keys(data.name).map((languageCode) => ({
      languageCode,
      text: watch(`name${languageCode}`),
    }));

    const descriptions = Object.keys(data.description).map((languageCode) => ({
      languageCode,
      text: watch(`description${languageCode}`),
    }));

    const variations = [
      {
        count: 1000,
        compensationOnly: false,
        productId: null,
        attributeValues: data?.attrs?.map((attr) => ({
          attributeId: attr.attributeId,
          value: attr.value,
          productId: null,
          valueTranslations: [
            { languageCode: "ru", text: attr.valueTranslations.ru },
            { languageCode: "en", text: attr.valueTranslations.en },
            {
              languageCode: "uz-Latn-UZ",
              text: attr.valueTranslations["uz-Latn-UZ"],
            },
            {
              languageCode: "uz-Cyrl-UZ",
              text: attr.valueTranslations["uz-Cyrl-UZ"],
            },
          ],
          variationId: null,
        })),
        prices: data?.prices.map((price) => ({
          currencyId: 1,
          value: price.value === "" ? 0 : price.value,
          variationId: null,
          type: price.type,
        })),
        files: uploadedFiles,
        saleType: calculateSaleType(),
      },
    ];
    const attributeId = data?.categoryId?.value;
    const datas = {
      name: names,
      description: descriptions,
      brandId: 1,
      categoryId: attributeId,
      organizationId: profile?.result?.organizationId,
      variations: variations,
    };

    try {
      await ProductsPost(datas);
      toggleTab(activeTab + 1, 100);
      client.invalidateQueries({ queryKey: ["moderations"] });
      navigate("/products-moderate");
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setProductLoading(false);
    }
  };

  const { fields } = useFieldArray({
    control,
    name: "attrs",
  });
  return (
    <div className="page-content">
      <Col className="w-50">
        <Card>
          <CardBody>
            <div className="progress-nav mb4">
              <Progress value={progressbarvalue} style={{ height: "1px" }} />

              <Nav
                className="nav-pills progress-bar-tab custom-nav"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    id="pills-gen-info-tab"
                    className={classnames(
                      {
                        active: activeTab === 1,
                        done: activeTab <= 4 && activeTab >= 0,
                      },
                      "rounded-pill"
                    )}
                    tag="button"
                  >
                    1
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    id="pills-profile-tab"
                    className={classnames(
                      {
                        active: activeTab === 2,
                        done: activeTab <= 4 && activeTab > 1,
                      },
                      "rounded-pill"
                    )}
                    tag="button"
                  >
                    2
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent
              activeTab={activeTab}
              className="form-steps-tab-content"
            >
              <TabPane tabId={1}>
                <AddProductForm
                  loading={loading}
                  Submit={Submit}
                  setValue={setValue}
                />
              </TabPane>

              <TabPane tabId={2}>
                <Form>
                  <div className="d-flex gap-3 align-items-center">
                    <div className="w-100">
                      <Label>
                        {t("Price")}
                        <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        control={control}
                        name="prices[0].value"
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                      {errors?.prices?.[0]?.value && (
                        <div className="text-danger">
                          {t(errors?.prices[0]?.value?.message)}
                        </div>
                      )}
                    </div>
                    <div className="w-100">
                      <Label>
                        {t("Including VAT")}{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        control={control}
                        name="prices[1].value"
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                      {errors?.prices?.[1]?.value && (
                        <div className="text-danger">
                          {t(errors?.prices[1]?.value?.message)}
                        </div>
                      )}
                    </div>
                    <div className="w-100">
                      <Label>
                        {t("MSRP")}
                        <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        control={control}
                        name="prices[2].value"
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                      {errors?.prices?.[2]?.value && (
                        <div className="text-danger">
                          {t(errors?.prices?.[2]?.value?.message)}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="sale-type">{t("Transaction type")}</Label>
                    <div className="d-flex flex-column">
                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-available"
                          value={2}
                          onChange={() => handleSaleTypeChange(2)}
                          checked={watch("saleType").includes(2)}
                        />
                        <Label htmlFor="order-available">Компенсация</Label>
                      </div>

                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="pre-order"
                          value={1}
                          onChange={() => handleSaleTypeChange(1)}
                          checked={watch("saleType").includes(1)}
                        />
                        <Label htmlFor="pre-order">Рассрочка</Label>
                      </div>

                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-in-2-4-weeks"
                          value={4}
                          onChange={() => handleSaleTypeChange(4)}
                          checked={watch("saleType").includes(4)}
                        />
                        <Label htmlFor="order-in-2-4-weeks">Продажа</Label>
                      </div>
                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="BMT"
                          value={8}
                          onChange={() => handleSaleTypeChange(8)}
                          checked={watch("saleType").includes(8)}
                        />
                        <Label htmlFor="BMT">ООН</Label>
                      </div>
                    </div>
                    {errors.saleType && (
                      <div className="text-danger">
                        {errors.saleType.message}
                      </div>
                    )}
                  </div>

                  <div className="d-flex gap-2 flex-wrap">
                    {fileInputs.map((input, index) => (
                      <div key={index} style={{ width: "300px" }}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleFileUpload(acceptedFiles, index)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({ className: "dropzone" })}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                height: "150px",
                                border: "2px dashed #cccccc",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                            >
                              {!filePreviews[index] && (
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className="bx bx-image-add display-4"></i>
                                </div>
                              )}
                              {filePreviews[index] && (
                                <img
                                  src={filePreviews[index]}
                                  alt={`Preview ${index}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    ))}
                    {errors.files && (
                      <div className="text-danger">{errors.files.message}</div>
                    )}
                  </div>

                  <div className="d-flex flex-column gap-3">
                    {fields.map((item, idx) => (
                      <div key={idx}>
                        {item.filter ? (
                          item.filter.filterType === "Range" ||
                          item.filter.filterType === "Price" ? (
                            <div>
                              <div className="d-flex flex-column">
                                <Label style={{ fontWeight: 550 }}>
                                  {item.name}
                                </Label>
                                <span style={{ color: "gray" }}>
                                  {item?.filter?.values}
                                </span>
                              </div>
                              <Controller
                                control={control}
                                name={`attrs[${idx}].value`}
                                render={({ field }) => (
                                  <ReactInput
                                    {...field}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                  />
                                )}
                              />
                              {errors.attrs &&
                                errors.attrs[idx] &&
                                errors.attrs[idx].value && (
                                  <span className="text-danger">
                                    {errors.attrs[idx].value.message}
                                  </span>
                                )}
                            </div>
                          ) : item.filter.filterType === "SingleSelect" ? (
                            <div>
                              <Label style={{ fontWeight: 550 }}>
                                {item.name}
                              </Label>
                              {item.filter.values
                                .split(",")
                                .map((value, valueIdx) => (
                                  <div key={valueIdx} className="form-check">
                                    <Controller
                                      control={control}
                                      name={`attrs[${idx}].value`}
                                      render={({ field }) => (
                                        <ReactInput
                                          {...field}
                                          value={value.trim()}
                                          type={"radio"}
                                          id={value}
                                        />
                                      )}
                                    />
                                    <Label
                                      htmlFor={value}
                                      className="form-check-label"
                                    >
                                      {value === "0"
                                        ? t("Yes")
                                        : value === "1"
                                        ? t("No")
                                        : value}
                                    </Label>
                                  </div>
                                ))}
                              {errors.attrs &&
                                errors.attrs[idx] &&
                                errors.attrs[idx].value && (
                                  <span className="text-danger">
                                    {errors.attrs[idx].value.message}
                                  </span>
                                )}
                            </div>
                          ) : item.filter.filterType === "MultipleSelect" ? (
                            <div>
                              <Label style={{ fontWeight: 550 }}>
                                {item.name}
                              </Label>
                              {item.filter.values
                                .split(",")
                                .map((value, valueIdx) => (
                                  <div key={valueIdx} className="form-check">
                                    <Controller
                                      control={control}
                                      name={`attrs[${idx}].value`}
                                      render={({ field }) => (
                                        <ReactInput
                                          {...field}
                                          value={value.trim()}
                                          type="checkbox"
                                          checked={field.value
                                            .split(",")
                                            .includes(value.trim())}
                                          onChange={(e) =>
                                            handleMultipleSelectChange(
                                              field,
                                              value,
                                              e
                                            )
                                          }
                                          id={value.trim()}
                                        />
                                      )}
                                    />
                                    <Label
                                      htmlFor={value.trim()}
                                      className="form-check-label"
                                    >
                                      {value}
                                    </Label>
                                  </div>
                                ))}
                              {errors.attrs &&
                                errors.attrs[idx] &&
                                errors.attrs[idx].value && (
                                  <span className="text-danger">
                                    {errors.attrs[idx].value.message}
                                  </span>
                                )}
                            </div>
                          ) : null
                        ) : !item.isValueTranslated ? (
                          <div>
                            <Label>{item.name}</Label>
                            <Controller
                              control={control}
                              name={`attrs[${idx}].value`}
                              render={({ field }) => (
                                <ReactInput
                                  type={
                                    item.dataType === "Float" ||
                                    item.dataType === "Integer" ||
                                    item.dataType === "Number"
                                      ? "number"
                                      : "text"
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  {...field}
                                />
                              )}
                            />
                            {errors.attrs &&
                              errors.attrs[idx] &&
                              errors.attrs[idx].value && (
                                <span className="text-danger">
                                  {errors.attrs[idx].value.message}
                                </span>
                              )}
                          </div>
                        ) : (
                          item.isValueTranslated && (
                            <div className="d-flex flex-column gap-3">
                              <Label>{item.name}</Label>
                              <Controller
                                control={control}
                                name={`attrs[${idx}].valueTranslations.ru`}
                                render={({ field }) => (
                                  <ReactInput
                                    {...field}
                                    placeholder={t("in russian")}
                                  />
                                )}
                              />
                              {errors.attrs &&
                                errors.attrs[idx] &&
                                errors.attrs[idx]?.valueTranslations && (
                                  <span className="text-danger">
                                    {
                                      errors.attrs[idx]?.valueTranslations?.ru
                                        ?.message
                                    }
                                  </span>
                                )}

                              <Controller
                                control={control}
                                name={`attrs[${idx}].valueTranslations.uz-Latn-UZ`}
                                render={({ field }) => (
                                  <ReactInput
                                    {...field}
                                    placeholder={t("in uzbek(kyr)")}
                                  />
                                )}
                              />
                              {errors.attrs &&
                                errors.attrs[idx] &&
                                errors.attrs[idx]?.valueTranslations && (
                                  <span className="text-danger">
                                    {
                                      errors.attrs[idx]?.valueTranslations?.[
                                        "uz-Latn-UZ"
                                      ]?.message
                                    }
                                  </span>
                                )}
                              <Controller
                                control={control}
                                name={`attrs[${idx}].valueTranslations.uz-Cyrl-UZ`}
                                render={({ field }) => (
                                  <ReactInput
                                    {...field}
                                    placeholder={t("in uzbek(lat)")}
                                  />
                                )}
                              />
                              {errors.attrs &&
                                errors.attrs[idx] &&
                                errors.attrs[idx]?.valueTranslations && (
                                  <span className="text-danger">
                                    {
                                      errors.attrs[idx]?.valueTranslations?.[
                                        "uz-Cyrl-UZ"
                                      ]?.message
                                    }
                                  </span>
                                )}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="d-flex align-items-center gap-2 justify-content-end mt-2">
                    <Button
                      outline
                      onClick={() => {
                        toggleTab(1, 0);
                        reset();
                      }}
                    >
                      {t("Back")}
                    </Button>
                    <Button
                      onClick={handleSubmit(ProductSubmit)}
                      className="d-flex align-items-center gap-2"
                    >
                      {productLoading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      {t("Save")}
                      <i className="ri-check-line"></i>
                    </Button>
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default AddProducts;
