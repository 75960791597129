export const fileExtensionsTypeObj = {
  ["image/png"]: "png",
  ["image/jpeg"]: "jpeg",
  ["image/jpg"]: "jpg",
  ["image/gif"]: "gif",
  ["application/pdf"]: "pdf",
  ["application/zip"]: "zip",
  ["video/mp4"]: "mp4",
  ["application/x-rar-compressed"]: "rar",
  ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]:
    "docx",
};
