import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import classnames from "classnames";
import { useQuery } from "@tanstack/react-query";
import { getOrders2Query } from "../../../queries";
import createSchema from "../../../helpers/createSchema";
import { getOrders2 } from "../../../api";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import { prettify } from "../ui/prettify";
import ReserveTable from "../ui/reserve-table";
const values = {
  page: 1,
  size: 20,
  desc: true,
  subOrderState: ""
};
const schema = createSchema({});
const Orders2 = () => {
  const tableKey = "orders2";
  const { t } = useTranslation();
  const [allDataLoading, setAllDataLoading] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getOrders2Query({ ...watch() }),
  });

  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);
  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };

  const getAllData = () => {
    setAllDataLoading(true);
    getOrders2({
      ...watch(),
      size: data?.pagination?.TotalCount,
    })
      .then((res) => {
        setDataForDownload(res?.result?.items?.[0]);

        setExportModal(true);
      })
      .finally(() => setAllDataLoading(false));
  };

  const columns = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: (row) => moment(row.createdDate).format("YYYY.MM.DD"),
        filterable: false,
      },
      {
        Header: t("Price"),
        accessor: (row) =>
          prettify(
            row.subOrders?.[0]?.items?.[0].variation?.prices?.find(
              (item) => item.type === "Price"
            ).value
          ),
        filterable: false,
      },
    ],
    []
  );

  return (
    <div className="page-content">
      {/* <ExportCSVModal
        show={exportModal}
        onCloseClick={() => setExportModal(false)}
        data={dataForDownload}
        fileName="ЗАЯВКИ"
      /> */}
      <Container fluid>
        <BreadCrumb title={t("reserve")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("reserve")}</h5>
                  </div>
                  {/* <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={getAllData}
                        disabled={allDataLoading}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t(allDataLoading ? "downloading" : "export")}
                      </button>
                    </div>
                  </div> */}
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === "" },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", "")}
                        href="#"
                      >
          
                        {t("all_reserve")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 0 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 0)}
                        href="#"
                      >
                
                        {t("Новый заказ")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 1 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 1)}
                        href="#"
                      >
                    
                        {t("Идет подготовка")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 2 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 2)}
                        href="#"
                      >
                     
                        {t("Отправленный")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 3 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 3)}
                        href="#"
                      >
                 
                        {t("Завершено")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 4 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 4)}
                        href="#"
                      >
                   
                        {t("Отменено")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("subOrderState") === 5 },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("subOrderState", 5)}
                        href="#"
                      >
            
                        {t("Возврат денег")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  {isFetched ? (
                    <>
                      <ReserveTable
                        data={data?.result?.items}
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                      />

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>

                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                        <option value={200}>{t("200")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Orders2;
