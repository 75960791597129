import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const CustomModal = ({
  isOpen,
  toggleModal,
  onConfirm,
  title,
  content,
  footerText,
  centered,
}) => {
  return (
    <Modal centered isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} >
        {title}
      </ModalHeader>
      <ModalBody style={{ maxHeight: "500px", overflowY: "auto" }}>
        <p>{content}</p>
      </ModalBody>
      <ModalFooter className="w-100">{footerText}</ModalFooter>
    </Modal>
  );
};

export default CustomModal;
