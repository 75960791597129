import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Input,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useQuery } from "@tanstack/react-query";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getOrdersQuery } from "../../../queries/index";
import Loader from "../../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getOrders } from "../../../api";

const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  desc: true,
};

const Orders = () => {
  const [allDataLoading, setAllDataLoading] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const tableKey = "orders";
  const { t } = useTranslation();

  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getOrdersQuery({
      ...watch(),
    }),
  });

  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);
  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };

  const getAllData = () => {
    setAllDataLoading(true);
    getOrders({
      ...watch(),
      size: data?.pagination?.TotalCount,
    })
      .then((res) => {
        setDataForDownload(res?.result?.orders);

        setExportModal(true);
      })
      .finally(() => setAllDataLoading(false));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/orders/${cell?.value}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: t("Region"),
        accessor: "region",
        filterable: false,
      },

      {
        Header: t("full_name"),
        accessor: "fullname",
        filterable: false,
      },

      {
        Header: t("phone"),
        accessor: "phone",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "organization",
        filterable: false,
      },
      {
        Header: t("created_date"),
        accessor: "orderDate",
        Cell: (order) => (
          <>{moment(order.row.original.createdDate).format("MM.DD.YYYY")}, </>
        ),
      },
      {
        Header: t("status"),
        accessor: "status",
        Cell: (cell) => {
          switch (cell.value) {
            case 0:
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {t("orderStatus0")}
                </span>
              );
            case 1:
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {t("orderStatus1")}
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase badge-soft-secondary">
                  {t("orderStatus2")}
                </span>
              );
            case 3:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {t("orderStatus3")}
                </span>
              );
            case 4:
              return (
                <span className="badge text-uppercase badge-soft-primary">
                  {t("orderStatus4")}
                </span>
              );
            case 5:
              return (
                <span className="badge text-uppercase badge-soft-success">
                  {t("orderStatus5")}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {t("orderStatus6")}
                </span>
              );
          }
        },
      },
    ],
    []
  );

  document.title = `${t("Orders")} | Taqsim`;

  return (
    <div className="page-content">
      <ExportCSVModal
        show={exportModal}
        onCloseClick={() => setExportModal(false)}
        data={dataForDownload}
        fileName="ЗАЯВКИ"
      />
      <Container fluid>
        <BreadCrumb title={t("orders")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("orders")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={getAllData}
                        disabled={allDataLoading}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t(allDataLoading ? "downloading" : "export")}
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("isInstallment") === "" },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("isInstallment", "")}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        {t("all_orders")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("isInstallment") === false },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("isInstallment", false)}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
                        {t("compensation")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("isInstallment") === true },
                          "fw-semibold"
                        )}
                        onClick={() => setValue("isInstallment", true)}
                        href="#"
                      >
                        <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>{" "}
                        {t("installment")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={data?.result?.orders || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1 "
                        tableClass="align-middle table-nowrap "
                        theadClass="table-light text-muted"
                      >
                        {" "}
                        {data?.result?.orders?.length === 0 && (
                          <div className="text-center ">{t("No data")}</div>
                        )}
                      </TableContainer>

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>

                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                        <option value={200}>{t("200")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Orders;
