export function prettify(num) {
  if (!num) return num;
  const regExp = /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g;

  const res = num.toString().split(".");
  res[0] = res[0].replace(regExp, "$1 ");
  if (res[1]) {
    res[1] = res[1].slice(0, 2);
  }
  return res.join(".");
}
export const formatAccountNumber = (value) => {
  return value?.replace(/\s?/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
};

export const unformatAccountNumber = (value) => {
  return value?.replace(/\s/g, "");
};
export const formatAccountNumber3 = (value) => {
  return (
    value?.replace(/\s?/g, "").replace(/(\d{3})(?=\d)/g, "$1 ") 
  );
};


