import React, { useEffect, useMemo } from "react";
import Loader from "../../../Components/Common/Loader";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getClientsQuery } from "../../../queries";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";

const Clients = () => {
  const schema = createSchema({});
  const values = {
    desc: true,
    size: 20,
    page: 1,
    findFieldType: 3,
    fieldValue: "",
  };
  const { t } = useTranslation();
  const { watch, setValue } = useHookForm(values, schema);
  const tableKey = "clients";
  const { data, isFetched } = useQuery({
    ...getClientsQuery({
      desc: watch("desc"),
      size: watch("size"),
      page: watch("page"),
      findFieldType: watch("findFieldType"),
      fieldValue: watch("fieldValue"),
    }),
  });
  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };
  const handleSearchChange = (e) => {
    setValue("fieldValue", e.target.value);
  };

  const columns = useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      filterable: false,
      Cell: (cell) => {
        return (
          <Link
            to={`/clients/${cell?.value}`}
            className="fw-medium link-primary"
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: t("Full name of the client"),
      accessor: (row) =>
        row?.lastName + " " + row?.firstName + " " + row?.middleName,
      filterable: false,
    },

    {
      Header: t("Phone number"),
      accessor: "phoneNumber",
      filterable: false,
    },
    {
      Header: t("Passport series"),
      accessor: "passportSerial",
      filterable: false,
    },

    {
      Header: t("Passport number"),
      accessor: "passportNumber",
      filterable: false,
    },

    {
      Header: t("PINFL/INN"),
      accessor: "pinfl",
      filterable: false,
    },
  ]);
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3 ">
              <h2>{t("Clients")}</h2>
              <div className="d-flex align-items-center gap-3 ">
                <Input
                  type="number"
                  value={watch("fieldValue")}
                  onChange={handleSearchChange}
                  placeholder={t("Search by phone number")}
                  className=" form-control "
                  id="searchInput"
                />
                {/* 
                <Button className="d-flex gap-1 btn btn-success align-items-center w-75">
                  {t("Export to Excel")}
                  <i className=" ri-arrow-right-s-line "></i>
                </Button> */}
                {/* <Button className="d-flex gap-1 btn btn-light align-items-center">
                  {t("Filter")}
                  <i className=" ri-equalizer-fill "></i>
                </Button> */}
              </div>
            </div>
            <div>
              {isFetched ? (
                <>
                  <TableContainer
                    pagination={{
                      currentPage: watch("page"),
                      totalPages: data?.pagination?.TotalPages,
                      onChange: (page) => setValue("page", page),
                    }}
                    columns={columns}
                    data={data?.result.items}
                    isAddUserList={false}
                    divClass="table-responsive table-card mb-1 "
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                  >
                    {data?.result?.items?.length === 0 && (
                      <div className="text-center ">{t("No data")}</div>
                    )}
                  </TableContainer>
                  <Input
                    type="select"
                    name="pageSize"
                    id="pageSize"
                    value={watch("size")}
                    onChange={handlePageSizeChange}
                    className="w-auto"
                  >
                    <option value={20}>{t("20")}</option>
                    <option value={50}>{t("50")}</option>
                    <option value={100}>{t("100")}</option>
                    <option value={200}>{t("200")}</option>
                    <option value={500}>{t("500")}</option>
                  </Input>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Clients;
