import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getClientsId } from "../../../api";
import { useTranslation } from "react-i18next";
import { Input, Label, Spinner } from "reactstrap";
import Flatpickr from "react-flatpickr";

const ClientsSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryKey: ["clients-single", id],
    queryFn: () => getClientsId(id),
  });

 
  return (
    <div className="page-content w-75">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-start gap-3">
          <i
            className="ri-arrow-left-line fs-3 cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          <h2>{t("Customer data")}</h2>
        </div>
        <div>
          <h4
            className={
              data?.result.state === 0 ? "text-danger" : "text-success"
            }
            style={{ fontWeight: 600 }}
          >
            {data?.result?.state === 0
              ? t("Not active")
              : data?.result.state === 1
              ? t("Active")
              : null}
          </h4>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className=" mb-3">
          <section className="d-flex flex-column gap-3 ">
            <div className="w-100">
              <Label>{t("Phone number")}</Label>
              <Input
                className="form-control border-dashed p-3 "
                value={data?.result?.phoneNumber}
                readOnly
              />
            </div>
            <div className="d-flex gap-5">
              <div className="w-50">
                <Label>{t("Document type")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={
                    data?.result?.passportType.name === "IdCard"
                      ? t("ID Card")
                      : data.result.passportType.name === "BioPassport"
                      ? t("Passport")
                      : null
                  }
                  readOnly
                />
              </div>
              <div className="w-50">
                <Label>{t("Passport details")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={
                    data?.result?.passportSerial +
                    " " +
                    data?.result?.passportNumber
                  }
                  readOnly
                />
              </div>
            </div>
            <div className="d-flex gap-5">
              <div className="w-50">
                <Label>{t("Last name")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={data?.result?.lastName}
                  readOnly
                />
              </div>
              <div className="w-50">
                <Label>{t("First name")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={data?.result?.firstName}
                  readOnly
                />
              </div>
            </div>
            <div className="d-flex gap-5">
              <div className="w-50">
                <Label>{t("Middle name")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={data?.result?.middleName}
                  readOnly
                />
              </div>
              <div className="w-50">
                <Label>{t("Birth date")}</Label>
                <Flatpickr
                  className="form-control p-3 cursor-none border-dashed"
                  disabled={true}
                  options={{ dateFormat: "d M, Y" }}
                  value={data?.result?.birthDate}
                  placeholder={t("Birth date")}
                />
              </div>
            </div>
            <div>
              <Label>{t("Residential address")}</Label>
              <Input
                className="form-control border-dashed p-3 "
                value={data?.result?.address}
                readOnly
              />
            </div>
            <div className="d-flex gap-5">
              <div className="w-50">
                <Label>{t("PINFL")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={data?.result?.pinfl}
                  readOnly
                />
              </div>
              <div className="w-50">
                <Label>{t("Card number")}</Label>
                <Input
                  className="form-control p-3 cursor-none border-dashed"
                  value={data?.result?.cardNumber}
                  readOnly
                />
              </div>
            </div>
            <h3 className="mt-3" style={{ fontWeight: 600 }}>
              {t("Preliminary scoring")}
            </h3>
            <div className="d-flex gap-5">
              <div className="w-50">
                <Label>{t("Ball")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={data?.result?.scoring?.ball}
                  placeholder={t("Ball not available")}
                  readOnly
                />
              </div>
              <div className="w-50">
                <Label>{t("Available amount")}</Label>
                <Input
                  className="form-control border-dashed p-3 "
                  value={data?.result?.scoring?.enableSumma}
                  placeholder={t("no information about the available amount")}
                  readOnly
                />
              </div>
            </div>
            <div>
              <Label>{t("Comments")}</Label>
              <Input
                className="form-control border-dashed p-3 "
                value={data?.result?.scoring?.comment}
                placeholder={t("Comment not attached")}
                readOnly
              />
            </div>
            <div>
              <Label>{t("Reason")}</Label>
              <Input
                className="form-control border-dashed p-3 "
                placeholder={t("Reason not provided")}
                value={
                  data?.result?.scoring?.reason === "ClientClaimRegisterError" ? t("Incorrect information in the application") : ""
                }
              />
            </div>
          </section>

          {data?.result?.scoring?.underWriter ? (
            <section>
              <h3 className="mt-3 mb-5 fw-semibold">
                Информация по подсчету баллов
              </h3>
              {data?.result?.scoring?.underWriter?.infos?.map((item) => (
                <div key={item.id} className="d-flex flex-column gap-2 mb-2">
                  <h4 style={{ fontWeight: 600 }}>{item.key_title}</h4>
                  <div>
                    {item?.items?.map((child) => (
                      <div
                        key={child.scoring_category_id}
                        className="d-flex flex-column  mb-3"
                      >
                        <Label>{child.scoring_category_name}</Label>
                        <Input
                          className="form-control border-dashed p-3 "
                          value={child.scoring_category_value_name}
                          readOnly
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ClientsSingle;
