import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useQuery } from "@tanstack/react-query";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import TableContainer from "../../Components/Common/TableContainer";
import { getContractsQuery } from "../../queries/index";
import Loader from "../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";

const Contracts = () => {
  const [activeTab, setActiveTab] = useState("");
  const [isExportCSV, setIsExportCSV] = useState(false);
  const { t } = useTranslation();

  const { data, isFetched } = useQuery({
    ...getContractsQuery({
      pageSize: 100000,
      pageIndex: 1,
      saleType: activeTab,
    }),
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to="/apps-ecommerce-order-details"
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: t("organization_name"),
        accessor: "organizationName",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "client.fullName",
        filterable: false,
      },
      {
        Header: t("full_name"),
        accessor: "fullname",
        filterable: false,
      },
      {
        Header: t("phone"),
        accessor: "phone",
        filterable: false,
      },
      {
        Header: t("created_date"),
        accessor: "orderDate",
        Cell: (order) => (
          <>
            {moment(order.row.original.createdDate).format("MM.DD.YYYY")},{" "}
            <small className="text-muted">
              {moment(order.row.original.createdDate).format("HH:mm")}
            </small>
          </>
        ),
      },
      {
        Header: "Delivery Status",
        accessor: "status",
        Cell: (cell) => {
          switch (cell.value) {
            case 0:
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {t("orderStatus0")}
                </span>
              );
            case 1:
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {t("orderStatus1")}
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase badge-soft-secondary">
                  {t("orderStatus2")}
                </span>
              );
            case 3:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {t("orderStatus3")}
                </span>
              );
            case 4:
              return (
                <span className="badge text-uppercase badge-soft-primary">
                  {t("orderStatus4")}
                </span>
              );
            case 5:
              return (
                <span className="badge text-uppercase badge-soft-success">
                  {t("orderStatus5")}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {t("orderStatus6")}
                </span>
              );
          }
        },
      },

      {
        Header: "Action",
        Cell: () => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to="/apps-ecommerce-order-details"
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Contracts | Taqsim";

  return (
    <div className="page-content">
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={data?.contracts}
      />
      <Container fluid>
        <BreadCrumb title={t("contracts")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("contracts")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => setIsExportCSV(true)}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t("export")}
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "" },
                          "fw-semibold"
                        )}
                        onClick={() => setActiveTab("")}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        {t("all_orders")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => setActiveTab("1")}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
                        {t("compensation")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "0" },
                          "fw-semibold"
                        )}
                        onClick={() => setActiveTab("0")}
                        href="#"
                      >
                        <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>{" "}
                        {t("installment")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {isFetched ? (
                    <TableContainer
                      columns={columns}
                      data={data?.contracts || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={15}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                      isOrderFilter={true}
                      SearchPlaceholder={t("search")}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contracts;
