import React from "react";
import { Controller } from "react-hook-form";
import { Button, Form, Input, Label } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { t } from "i18next";
import useHookForm from "../../../../hooks/useHookForm";
import createSchema from "../../../../helpers/createSchema";

const ProductsFilterForm = ({ onSubmit, resetForm, control, watch }) => {
  return (
    <div>
      <Form onSubmit={onSubmit} className="d-flex flex-column gap-3">
        <Controller
          name="fromDate"
          control={control}
          render={({ field }) => (
            <Flatpickr
              className="form-control"
              options={{ dateFormat: "d M, Y" }}
              onChange={(date) => {
                const adjustedDate = new Date(
                  Date.UTC(
                    date[0].getFullYear(),
                    date[0].getMonth(),
                    date[0].getDate()
                  )
                );
                field.onChange(adjustedDate);
              }}
              value={field.value ? new Date(field.value) : ""}
              placeholder={t("Birth date")}
            />
          )}
        />

        <Controller
          name="toDate"
          control={control}
          render={({ field }) => (
            <Flatpickr
              className="form-control"
              options={{ dateFormat: "d M, Y" }}
              onChange={(date) => {
                // Adjust the date to remove the timezone issue
                const adjustedDate = new Date(
                  Date.UTC(
                    date[0].getFullYear(),
                    date[0].getMonth(),
                    date[0].getDate()
                  )
                );
                field.onChange(adjustedDate);
              }}
              value={field.value ? new Date(field.value) : ""}
              placeholder={t("Birth date")}
            />
          )}
        />
        <div>
          <Label style={{ fontWeight: 600 }}>{t("Job title")}</Label>
          <Controller
            name="state"
            control={control}
            className="bg-white"
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  {
                    value: 0,
                    label: t("Product deactivated"),
                  },
                  {
                    value: 1,
                    label: t("Product active"),
                  },
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label style={{ fontWeight: 600 }}>{t("Search by full name")}</Label>
          <Controller
            control={control}
            name="categoryName"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder={t("Enter your full name")}
              />
            )}
          />
        </div>
        <div>
          <Label style={{ fontWeight: 600 }}>{t("Search by address")}</Label>
          <Controller
            control={control}
            name="productName"
            render={({ field }) => (
              <Input {...field} type="text" placeholder={t("Enter address")} />
            )}
          />
        </div>
        <div className="d-flex justify-content-end gap-2 ">
          <Button
            disabled={
              !watch("fromDate") &&
              !watch("toDate") &&
              !watch("categoryName") &&
              watch("state") == null &&
              !watch("productName")
            }
            className="btn-light"
            onClick={resetForm}
          >
            Reset
          </Button>
          <Button
            disabled={
              !watch("fromDate") &&
              !watch("toDate") &&
              !watch("categoryName") &&
              watch("state") == null &&
              !watch("productName")
            }
            className="btn-primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProductsFilterForm;
