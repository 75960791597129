import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Spinner,
  Input,
  Label,
  Form,
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useQuery } from "@tanstack/react-query";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getProductsQuery } from "../../../queries/index";
import Loader from "../../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import CustomModal from "../../../Components/Common/Modal";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import ProductsFilterForm from "./Components/products-filter-form";
const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  moderationStatus: 1
};

const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableKey = "products";
  const { watch, setValue, control, handleSubmit, reset } = useHookForm(
    values,
    schema
  );
  const [modal, setModal] = useState(false);
  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const { data, isFetched } = useQuery({
    ...getProductsQuery(watch()),
  });

  const Submit = (data) => {
    const formatDate = (date) =>
      date ? moment(date).format("ddd, D MMM YYYY HH:mm:ss [GMT]") : null;
    const datas = {
      state: watch("state")?.value,
      categoryName: data?.categoryName,
      productName: data?.productName,
      fromDate: formatDate(data.fromDate),
      toDate: formatDate(data?.toDate),
      page: watch("page"),
      size: watch("size"),
    };
    toggleModal();
  };

  const resetForm = () => {
    reset();
    toggleModal();
  };

  // Column
  const columns = useMemo(() => [
    {
      Header: t("Product name"),
      accessor: "name",
      filterable: false,
      Cell: ({ row }) => {
        return (
          <Link
            to={`/products/${row.original.id}`}
            className="fw-medium link-primary"
          >
            {row.original.name}
          </Link>
        );
      },
    },
    {
      Header: t("Product category"),
      accessor: (row) => row?.category?.name,
      filterable: false,
    },
    {
      Header: t("Price"),
      accessor: (row) =>
        row?.variations && row.variations[0]
          ? row.variations[0].originPrice
          : "",
      filterable: false,
    },

    {
      Header: t("Visible"),
      accessor: "isVisible",
      filterable: false,
      Cell: ({ value }) => {
        return value === false ? (
          <span className="badge text-uppercase bg-danger ">не видно</span>
        ) : (
          <span className="badge text-uppercase bg-success">видно</span>
        );
      },
    },

    {
      Header: t("Status"),
      accessor: "state",
      filterable: false,
      Cell: ({ value }) => {
        switch (value) {
          case 1:
            return (
              <span className="badge text-uppercase bg-success ">
                {t("Active")}
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase  bg-danger">
                {t("Not active")}
              </span>
            );
        }
      },
    },

    {
      Header: t("Actions"),
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item">
              <Button
                color="link"
                className="display-4 text-black"
                onClick={() => window.open(`/products/edit/${value}`, "_blank")}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
            </li>
          </ul>
        );
      },
    },
  ]);
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>{t("Products")}</h2>
              <div className="d-flex align-items-center gap-2">
                {/* <Button
                  onClick={toggleModal}
                  className="btn-light d-flex align-items-center gap-1"
                >
                  <i className="ri-equalizer-fill"></i>
                  {t("Filter")}
                </Button> */}
                <Button
                  onClick={() => navigate("/products/add")}
                  className="d-flex gap-1 align-items-center"
                >
                  {t("Add product")}
                  <i className=" ri-add-line fs-4"></i>
                </Button>
              </div>
            </div>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={data?.item}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        isGlobalSearch={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      >
                        {data?.item?.length === 0 && (
                          <div className="text-center">{t("No data")}</div>
                        )}
                      </TableContainer>

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Spinner />
                  )}

                  <CustomModal
                    isOpen={modal}
                    toggleModal={toggleModal}
                    title={t("Filter")}
                    content={
                      <ProductsFilterForm
                        onSubmit={handleSubmit(Submit)}
                        resetForm={resetForm}
                        control={control}
                        watch={watch}
                      />
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;
