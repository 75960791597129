import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input as ReactInput,
  Label,
  Form,
  Spinner,
  Button,
} from "reactstrap";

import { userForgetPassword } from "../../store/actions";

import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import withRouter from "../../Components/Common/withRouter";
import { v4 as uuidv4 } from "uuid";
import useHookForm from "../../hooks/useHookForm";
import InputMasked from "../../Components/atoms/inputMask";
import removeChars from "../../Components/atoms/removeChars";
import { ToastContainer, toast } from "react-toastify";
import { recoverPassword } from "../../api";
import createSchema from "../../helpers/createSchema";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../Components/atoms/input";
import { t } from "i18next";
const schema = createSchema({});
const values = {
  phoneNumber: "",
  password: "",
  isTrusted: false,
  deviceId: uuidv4(),
  deviceType: 3,
  displayName: navigator.userAgent,
  userType: 3,
  otp: "",
};
const ForgetPasswordPage = () => {
  const [change, setChange] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useHookForm(values, schema);

  const Submit = async (res) => {
    setLoading(true);
    setPhoneNumber(res.phoneNumber);
    const data = {
      ...res,
      phoneNumber: removeChars(res.phoneNumber),
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };

    try {
      const res = await recoverPassword(data, "POST");
      toast(res.result.sentText, { type: "success", theme: "colored" });
      setChange(false);
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const SubmitPassword = async (res) => {
    setLoadingPassword(true);
    const data = {
      ...res,
      phoneNumber: removeChars(res.phoneNumber),
      otp: removeChars(res.otp),
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
    };

    try {
      await recoverPassword(data, "PUT");
      navigate("/login");
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoadingPassword(false);
    }
  };
  document.title = "Reset Password | Velzon - React Admin & Dashboard Template";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <ToastContainer position="top-center" />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">
                  Premium Admin & Dashboard Template
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2 ">
                    <h5 style={{ fontSize: "18px", fontWeight: 580 }}>
                      {t("Forgot your password")}
                    </h5>
                    <div className="d-flex flex-column gap-1">
                      <span style={{ fontSize: "23px", fontWeight: 550 }}>
                        {!change ? phoneNumber : null}
                      </span>
                      <span>
                        {!change
                          ? t(
                              "An SMS with a code has been sent to the specified number"
                            )
                          : null}
                      </span>
                    </div>
                  </div>

                  <div className="p-2">
                    {change ? (
                      <Form onSubmit={handleSubmit(Submit)}>
                        <div>
                          <Label>{t("phone")}</Label>
                          <InputMasked
                            control={control}
                            errors={errors}
                            name={"phoneNumber"}
                            maskProps={{ mask: "\\+\\9\\9\\8 99 999 99 99" }}
                          />
                        </div>

                        <div className="text-center mt-4">
                          <Button
                            className="btn btn-primary w-50"
                            type="submit"
                          >
                            {loading ? (
                              <div className="d-flex align-items-center gap-3 justify-content-center">
                                <Spinner size={"sm"} />
                                <div>{t("Next")}</div>
                              </div>
                            ) : (
                              <div>{t("Next")}</div>
                            )}
                          </Button>
                        </div>
                        <div className="d-flex gap-2 align-items-center justify-content-center mt-2">
                          <span>{t("I remembered")}</span>
                          <Link to={"/login"}>{t("Sign In")}</Link>
                        </div>
                      </Form>
                    ) : (
                      <Form
                        onSubmit={handleSubmit(SubmitPassword)}
                        className="d-flex flex-column gap-2"
                      >
                        <InputMasked
                          name={"otp"}
                          control={control}
                          errors={errors}
                          maskProps={{ mask: "999 999" }}
                          inputProps={{
                            placeholder: t("Enter confirmation code"),
                          }}
                        />
                        <div>
                          <Label>{t("Password")}</Label>
                          <Input
                            name="password"
                            control={control}
                            errors={errors}
                            inputProps={{
                              type: "password",
                              placeholder: t("Password"),
                            }}
                          />
                        </div>
                        <div>
                          <Label>{t("Confirm password")}</Label>
                          <Input
                            name="confirmPassword"
                            control={control}
                            errors={errors}
                            inputProps={{
                              type: "password",
                              placeholder: t("Confirm password"),
                            }}
                          />
                        </div>

                        <div className="text-center mt-4">
                          <Button
                            className="btn btn-primary w-50"
                            type="submit"
                          >
                            {loadingPassword ? (
                              <div className="d-flex align-items-center gap-3 justify-content-center">
                                <Spinner size={"sm"} />
                                <div>{t("Change")}</div>
                              </div>
                            ) : (
                              <div>{t("Change")}</div>
                            )}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(ForgetPasswordPage);
