import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getModerationId } from "../../../api/index";
import { useTranslation } from "react-i18next";
import { Card, Col, Input, Label, Row, Spinner } from "reactstrap";
import Cleave from "cleave.js/react";
import Dropzone from "react-dropzone";

const ProductsModerateSingle = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["moderationId", id],
    queryFn: () => getModerationId(id),
  });

  return (
    <div className="page-content">
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="d-flex align-items-start gap-3">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h2>{t("Product information")}</h2>
          </div>

          <div className="d-flex gap-5 justify-content-between mb-3">
            <section className="w-50 d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-3">
                {data?.result?.names?.map((item) => (
                  <div key={item.id}>
                    <Label className="fw-semibold">
                      {item.languageCode === "ru"
                        ? t("Name Russian language")
                        : item.languageCode === "uz-Cyrl-UZ"
                        ? t("Name Uzbek language (Cyrill.)")
                        : item.languageCode === "uz-Latn-UZ"
                        ? t("Name Uzbek language (lat.)")
                        : item.languageCode === "en"
                        ? t("name_en")
                        : ""}
                    </Label>
                    <Input
                      className="form-control border-dashed p-3"
                      value={item.text}
                      readOnly
                    />
                  </div>
                ))}
              </div>

              <div className="d-flex flex-column gap-3">
                {data?.result?.descriptions?.map((item) => (
                  <div key={item.id}>
                    <Label className="fw-semibold">
                      {item.languageCode === "ru"
                        ? t("Description Russian language")
                        : item.languageCode === "uz-Cyrl-UZ"
                        ? t("Description Uzbek language (Cyrill.)")
                        : item.languageCode === "uz-Latn-UZ"
                        ? t("Description Uzbek language (lat.)")
                        : item.languageCode === "en"
                        ? t("description_en")
                        : ""}
                    </Label>
                    <Input
                      className="form-control border-dashed p-3"
                      value={item.text}
                      readOnly
                    />
                  </div>
                ))}
              </div>
              <div>
                <Label className="fw-semibold">{t("VAT amount")}</Label>

                <Cleave
                  placeholder="Enter numeral"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                  }}
                  value={data?.result?.variations[0]?.prices[0]?.value}
                  className="form-control border-dashed p-3"
                  readOnly
                />
              </div>
              <div>
                <Label className="fw-semibold">{t("Product price")}</Label>
                <div className="d-flex flex-column gap-3">
                  {data?.result?.variations[0]?.prices.slice(1)?.map((item) => (
                    <div key={item.id}>
                      <Cleave
                        placeholder="Enter numeral"
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: "thousand",
                        }}
                        value={item.value}
                        className="form-control border-dashed p-3"
                        readOnly
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="w-50 gap-1 row p-3">
                {data?.result?.variations[0].files.map((item) => (
                  <Dropzone key={item.id} disabled={true}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable col-md-6 ">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <img
                            style={{
                              // maxWidth: "300px",
                              // maxHeight: "250px",
                              // objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            src={item?.url}
                            alt={item.id}
                          />
                        </div>
                      </div>
                    )}
                  </Dropzone>
                ))}
              </div> */}
              <div className=" w-100 gap-1 row p-3">
                {data?.result?.variations[0]?.files?.map((item) => (
                  <Dropzone key={item.id} disabled={true}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dropzone dz-clickable   col-md-6"
                        style={{ width: "280px" }}
                      >
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <img
                            style={{
                              maxWidth: "300px",
                              maxHeight: "250px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            src={item?.url}
                            alt={item.id}
                          />
                        </div>
                      </div>
                    )}
                  </Dropzone>
                ))}
              </div>
            </section>
            <section className="w-50 d-flex flex-column gap-3">
              {data?.result?.variations[0]?.attributeValues?.map((item) => {
                return (
                  <div key={item.id}>
                    <Label className="fw-semibold">
                      {" "}
                      {t(`${item?.attribute?.name}`)}
                    </Label>
                    <Input
                      className="form-control border-dashed p-3"
                      value={item.value}
                      readOnly
                    />
                  </div>
                );
              })}
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsModerateSingle;
